import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import tw, { css } from 'twin.macro'
import { fluid } from '../styles/typography'
import { StyleType } from '../utils/prop-types'
import Heading from './heading'
import Image from './image'
import Text from './text'

const Profile = ({ profile, style }) => {
  const title = useMemo(() => profile.jobTitle.split(' & '), [profile])
  return (
    <div
      css={[
        style,
      ]}
      aria-hidden="true"
    >
      <div css={tw`relative overflow-hidden h-profile-sm md:h-profile-md lg:h-profile`}>
        <Image
          image={profile.image}
          style={tw`lg:(h-full)`}
        />
      </div>
      <Heading
        content={profile.name}
        headingType="h4"
        style={css`
          ${tw`mt-6 mb-2 font-bold text-center text-primary-500`}
          line-height: 1.33;
          letter-spacing: 0.0025em;
          ${fluid(24, 28)}
        `}
      />
      {title.map((t) => (
        <Text
          key={t}
          content={t}
          textType="body-lg"
          style={[tw`font-normal text-center text-primary-500`]}
        />
      ))}
    </div>
  )
}

Profile.propTypes = {
  profile: PropTypes.object.isRequired,
  style: StyleType,
}

const Profiles = ({ profiles, style }) => {
  return (
    <div>
      <div
        css={[
          tw`flex flex-col space-y-14
          lg:(grid grid-cols-3 space-y-0 gap-y-24 gap-x-16)
          xl:grid-cols-4`,
          style,
        ]}
      >
        {profiles.map((p, i) => (
          <Profile key={p.name} profile={p} index={i} />
        ))}
      </div>
    </div>
  )
}

Profiles.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.object),
  style: StyleType,
}

export default Profiles
